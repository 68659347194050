<template>
  <div class="question column" :class="width">
    <div class="question-text">
      <p class="question-text__code" v-if="showQuestionCode">{{ question.code }} </p>
    </div>
    <div class="question-content card">
      <div class="card-content" v-html="decodeHtml(question.question[lang])"></div>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'TextDisplay',
  mixins: [QuestionMixin],
}
</script>
